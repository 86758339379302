<template>
  <BaseTooltip :tip="tooltip" small v-if="showTooltip">
    <template v-slot:content>
      <component
        :is="tag"
        @click="handleClick"
        :disabled="disabled || loading ? true : undefined"
        type="button"
        :class="[
          `app-button app-button--${type}`,
          { 'app-button--micro': micro },
          { 'app-button--small': small },
          { 'app-button--search--small': smallSearch },
          { 'app-button--medium': medium },
          { 'app-button--search': search },
          { 'app-button--icon': !label },
          { 'app-button--icon-right': iconRight },
          {
            'app-button--icon-text':
              label && (icon || customIcon || symbol || loading),
          },
          { '!pl-8': withPaddingLeft },
          { active: active },
        ]"
      >
        <TailwindComponentsLoading
          v-if="loading"
          :small="small || medium || search || iconSmall || loading"
          :medium="!small && !medium && !search && !iconSmall && !loading"
          :class="['app-button__icon', { 'opacity-0': !loading }]"
          :white="
            (type === 'primary' && !!label && !asLabel) ||
            type === 'secondary-text'
          "
        ></TailwindComponentsLoading>
        <i
          v-if="icon && !loading"
          :class="[
            `mdi mdi-${icon} app-button__icon`,
            { 'opacity-0': loading },
            { 'app-button__icon--small': iconSmall },
            { 'app-button__icon--big': iconBig },
          ]"
        ></i>
        <i
          v-if="symbol && !loading"
          :class="[
            `material-symbols-outlined app-button__icon`,
            { 'opacity-0': loading },
            { 'app-button__icon--small': iconSmall },
            { 'app-button__icon--big': iconBig },
          ]"
          >{{ symbol }}
        </i>
        <span
          v-else-if="customIcon && !loading"
          v-html="$svg[customIcon]"
          :class="[
            `app-button__icon--custom`,
            { 'opacity-0': loading },
            { 'app-button__icon--small': iconSmall },
            { 'app-button__icon--big': iconBig },
          ]"
        ></span>
        <span
          v-if="label"
          :class="[`whitespace-nowrap`, { 'font-semibold': bold }]"
          >{{ $t(label) }}</span
        >
      </component>
    </template>
  </BaseTooltip>
  <component
    v-else
    :is="tag"
    @click="handleClick"
    :disabled="disabled || loading ? true : undefined"
    type="button"
    :class="[
      `app-button app-button--${type}`,
      { 'app-button--micro': micro },
      { 'app-button--small': small },
      { 'app-button--search--small': smallSearch },
      { 'app-button--medium': medium },
      { 'app-button--search': search },
      { 'app-button--icon': !label },
      { 'app-button--icon-right': iconRight },
      {
        'app-button--icon-text':
          label && (icon || customIcon || symbol || loading),
      },
      { '!pl-8': withPaddingLeft },
      { active: active },
    ]"
  >
    <TailwindComponentsLoading
      v-if="loading"
      :small="small || medium || search || iconSmall || loading"
      :medium="!small && !medium && !search && !iconSmall && !loading"
      :class="['app-button__icon', { 'opacity-0': !loading }]"
      :white="
        (type === 'primary' && !!label && !asLabel) || type === 'secondary-text'
      "
    ></TailwindComponentsLoading>
    <i
      v-if="loading"
      class="app-button__icon loading loading-spinner loading-sm"
    ></i>
    <i
      v-if="icon && !loading"
      :class="[
        `mdi mdi-${icon} app-button__icon`,
        { 'opacity-0': loading },
        { 'app-button__icon--small': iconSmall },
        { 'app-button__icon--big': iconBig },
      ]"
    ></i>
    <i
      v-if="symbol && !loading"
      :class="[
        `material-symbols-outlined app-button__icon`,
        { 'opacity-0': loading },
        { 'app-button__icon--small': iconSmall },
        { 'app-button__icon--big': iconBig },
      ]"
      >{{ symbol }}
    </i>
    <span
      v-else-if="customIcon && !loading"
      v-html="$svg[customIcon]"
      :class="[
        `app-button__icon--custom`,
        { 'opacity-0': loading },
        { 'app-button__icon--small': iconSmall },
        { 'app-button__icon--big': iconBig },
      ]"
    ></span>
    <span
      v-if="label"
      :class="[`whitespace-nowrap`, { 'font-semibold': bold }]"
      >{{ $t(label) }}</span
    >
  </component>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    label?: string
    type?: string
    icon?: string
    symbol?: string
    customIcon?: string
    iconRight?: boolean
    iconBig?: boolean
    iconSmall?: boolean
    disabled?: boolean
    withPaddingLeft?: boolean
    preventDefault?: boolean
    micro?: boolean
    small?: boolean
    smallSearch?: boolean
    medium?: boolean
    search?: boolean
    bold?: boolean
    loading?: boolean
    active?: boolean
    asLabel?: boolean
    showTooltip?: boolean
    tooltip?: string
  }>(),
  {
    type: 'primary', // primary, secondary, text, secondary-text, danger
  }
)

const emit = defineEmits<{
  (e: 'click', event: Event): void
}>()

function handleClick(event: Event) {
  if (props.preventDefault) {
    event.preventDefault()
  }
  emit('click', event)
}

const tag = computed(() => {
  return props.asLabel ? 'label' : 'button'
})
</script>
